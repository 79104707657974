<template>
  <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess"
    :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
  >
    <AltStatus
      :blnalert="blnAltStatus"
      icon="CorrectFill"
      coloricon="fill-success"
      class="success"
      :name="$t('28')"
    />
    <AltStatus
      :blnalert="blnAltStatusunsucess"
      icon="IncorrectFill"
      coloricon="fill-danger"
      class="error"
      :name="$t('174')"
      @mousemove="hover = true"
      @mouseleave="hover = false"
    />
  </div>

  <div>
    <Table
      :progressScore="progress"
      v-show="ListSystemWord.dataFields != null"
      v-if="!blnOpenform"
      :ListDataTable="ListSystemWord"
      firstSort="strLDPEN"
      secondSort="description"
      nametable="Systemword"
      @edit="fetchGetSystemWordbyId($event)"
      ref="table"
    />
  </div>
</template>

<script>
import Table from "@/components/table/SystemWordTable.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import axios from "axios";
// import urlapi from "@/typescript/urlapi/url";

export default {
  components: {
    Table,
    AltStatus,
  },

  emits: ["edit"],
  data() {
    return {
      ListSystemWord: {},
      timerEnabled: false,
      timerCount: 0,
      progress: 0,
      ListDummy: {},
      activeFiltersSelected: [],
      listSystemWordFilter: [],
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      hover: false, //hover alt
      formdata: "",
    };
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCountHover++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    async fetchGetSystemWordbyId(id) {
      this.$emit("edit", id);
    },
    async processLoadingData() {
      this.progress = 0;
      this.ListDummy = [];
      this.ListSystemWord = [];
      this.timerCount = 0;
      this.fetchGetSystemWord().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            if (this.progress > 70) {
              // console.log("100");
              this.progress = 100;
            }
          }, 1000);
        });
      });
    },

    async fetchGetSystemWord() {
      this.timerCount = 0;
      this.timerEnabled = true;
      await axios
        .get("https://cesapi.wu.ac.th/ads" + "/v1/api/Systemword/SystemListAll")
        .then((res) => {
          // console.log(res.data);
          this.ListSystemWord = res.data;
        });
    },

    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      setTimeout(() => {
        if (localStorage.getItem("SelectedListSystemword") == null) {
          localStorage.setItem(
            "SelectedListSystemword",
            JSON.stringify(this.ListSystemWord.defaultField)
          );
        }
      }, 100);
      //delay 1 sec
      setTimeout(() => {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListSystemWord.total,
          this.ListSystemWord.data
        );

        let defaultField = [
          {
            field: "strLDPID",
            head: "1870",
          },
          {
            field: "strLDPTH",
            head: "1587",
          },
          {
            field: "strLDPEN",
            head: "1588",
          },
          {
            field: "strSystemlink",
            head: "697",
          },
          {
            field: "description",
            head: "699",
          },
        ];
        this.$refs.table.setSelectedList(defaultField);

        // this.$refs.table.setSelectedList(
        //   JSON.parse(localStorage.getItem("SelectedListSystemword"))
        // );
      }, 100);
      //this.$refs.filter.Clear();

      //this.timerEnabled = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom.scss";
</style>
